<script setup lang="ts">
import Spinner from './Spinner.vue'

const emit = defineEmits<{
  (e: 'done'): void
}>()

const props = withDefaults(
  defineProps<{
    items: {
      text: string
      icon: 'check' | 'x'
    }[]
    speed?: number
    blackVersion?: boolean
  }>(),
  {
    speed: 2000,
    blackVersion: false,
  }
)
const showing = ref(0)

const interval = setInterval(() => {
  showing.value++
  if (showing.value >= props.items.length) {
    clearInterval(interval)
    emit('done')
  }
}, props.speed || 2000)
</script>

<template>
  <div
    class="max-w-xs mx-auto space-y-3 text-start"
    :style="{
      minHeight: `${items.length * 1.75 + (items.length - 1) * 0.25}rem`,
    }"
  >
    <div
      v-for="(item, index) in items"
      :key="item.text"
      :class="[
        'flex items-center gap-4',
        index < showing
          ? blackVersion
            ? 'text-white'
            : 'text-black'
          : blackVersion
          ? 'text-gray-600'
          : 'text-gray-300',
      ]"
    >
      <template v-if="index < showing">
        <div v-if="item.icon === 'x'" class="bg-red-200 rounded-full p-1">
          <Icon i-heroicons-solid:x class="text-red-700" />
        </div>
        <div v-else class="bg-[#1ca74f] rounded-full p-1">
          <Icon i-heroicons-solid:check class="text-white" />
        </div>
      </template>
      <div v-else :class="[blackVersion ? '' : 'opacity-5']">
        <Spinner size="md" :white="blackVersion" />
      </div>
      <div class="flex-1 text-lg" v-html="item.text"> </div>
    </div>
  </div>
</template>
