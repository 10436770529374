<script setup lang="ts">
import blackBackground from '~/assets/find-right-plan-call-center-woman-black.png'
import whiteBackground from '~/assets/find-right-plan-call-center-woman.jpeg'
const peopleLooking = Math.floor(Math.random() * (200 - 100) + 100)

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state) {
    return `${state.name}, ${state.code}`
  }
  return null
})

const router = useRouter()
const route = useRoute()
const isMobile = useIsMobile()

const onFinish = () => {
  setTimeout(() => {
    router.replace({
      path: !isMobile.value
        ? '/ads'
        : `/additional-benefits-grocery-2024/${
            isWorkingHours.value ? 'call-now' : 'clicks'
          }`,
      query: route.query,
    })
  }, 2000)
}

analytics.page({
  name: 'Searching for plans',
  category: 'LP',
  product: 'medicare',
})

const blackVersion = computed(() =>
  route.query.black
    ? route.query.black === 'true'
    : exp.value?.getYoursLoadingDestination?.loading
)
const image = computed(() =>
  blackVersion.value ? blackBackground : whiteBackground
)

const computedLayoutCss = computed(() => {
  return [blackVersion ? 'bg-black text-white' : 'bg-white'].join(' ')
})
</script>
<template>
  <Layout
    :hide-header="true"
    :footer="brand.id === 'bridge' ? 'bridge' : '2025'"
    :class="computedLayoutCss"
  >
    <div
      class="container flex items-center p-4 flex flex-col gap-4 text-center"
      :class="[blackVersion ? 'bg-black text-white' : 'bg-white']"
    >
      <h2 class="text-3xl">
        Finding the
        <span
          class="font-bold"
          :class="[blackVersion ? 'text-[#FACC14]' : 'text-[#2581FE]']"
        >
          Right Plan for You!
        </span>
      </h2>
      <span class="text-lg">
        Our licensed insurance agent is findind <b>the right plan for you</b>.
        Please wait.
      </span>
      <div
        class="flex relative md:justify-start flex-grow w-full relative flex-grow-0"
      >
        <img
          :src="image"
          class="w-3/4 h-auto"
          :class="[isMobile ? 'mt-20' : 'mt-10']"
        />
        <div
          class="bubble from-them md:mt-20 md:-ml-20 flex-grow"
          :class="[blackVersion ? 'text-white black' : '']"
        >
          <span
            class="font-semibold"
            :class="[blackVersion ? 'text-[#FACC14]' : 'text-[#2581FE]']"
          >
            {{ peopleLooking }} people
          </span>
          from
          <span
            class="font-semibold"
            :class="[blackVersion ? 'text-[#FACC14]' : 'text-[#2581FE]']"
          >
            {{ state }}
          </span>
          are currently searching for plans.
        </div>
      </div>
      <div class="text-start">
        <Checklist2
          :black-version="blackVersion"
          :items="[
            {
              text: 'Searching our plan database',
              icon: 'check',
            },
            {
              text: 'Identifying insurance carriers',
              icon: 'check',
            },
            {
              text: `Finding Medicare plans in <b>${state || 'America'}</b>`,
              icon: 'check',
            },
          ]"
          @done="onFinish()"
        />
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.bubble {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem 0.875rem;
  position: absolute;
  word-wrap: break-word;
  @apply h-min top-0 right-6 max-w-1/2 md:right-24;
}

.bubble::before,
.bubble::after {
  bottom: -1rem;
  content: '';
  height: 1rem;
  position: absolute;
}

.bubble[class^='from-'] {
  margin: 0.5rem 0;
  width: fit-content;
}

.bubble.from-them {
  align-items: flex-start;
  @apply border-4 border-dashed border-[#2581FE];
}
.bubble.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #2581fe;
  left: -0.35rem;
  bottom: -1rem;
  transform: translate(0, -0.1rem);
}

.bubble.from-them::after {
  background-color: white;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}
.bubble.from-them.black::after {
  background-color: black;
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge", "m10"],
    "robots": "noindex"
  }
}
</route>
